import { useEffect } from 'react'

import styles from './adv-block.module.scss'
import { useColorMode } from '@chakra-ui/react'

export const AdvBlock = () => {
  const { colorMode } = useColorMode()
  useEffect(() => {
    const params =
      colorMode === `light` &&
      window.matchMedia(`(prefers-color-scheme: light)`).matches
        ? {
            blockId: 'R-A-9285186-1',
            renderTo: 'yandex_rtb_R-A-9285186-1',
          }
        : {
            blockId: 'R-A-9285186-2',
            renderTo: 'yandex_rtb_R-A-9285186-2',
          }

    // @ts-ignore
    window.yaContextCb?.push(() => {
      // @ts-ignore
      window.Ya.Context.AdvManager.render(params)
    })
  }, [colorMode])

  return (
    <div className={styles.wrapper}>
      {colorMode === `light` ? (
        <div id='yandex_rtb_R-A-9285186-1' />
      ) : (
        <div id='yandex_rtb_R-A-9285186-2'></div>
      )}
    </div>
  )
}
