import 'index.scss'

import React from 'react'
import ReactDOM from 'react-dom/client'

import { App } from 'app/app'
import * as serviceWorkerRegistration from 'app/serviceWorkerRegistration'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

serviceWorkerRegistration.register()
