import { useEffect, useRef } from 'react'

import { isYandexGames } from 'shared/lib'

export const YandexAdv = () => {
  const isYandexAdvInitialized = useRef(false)
  useEffect(() => {
    if (!isYandexGames() && !isYandexAdvInitialized.current) {
      // @ts-ignore
      window.yaContextCb = window.yaContextCb || []
      isYandexAdvInitialized.current = true
      const script = document.createElement(`script`)
      script.src = 'https://yandex.ru/ads/system/context.js'
      script.async = true
      document.body.appendChild(script)
    }
  }, [])
  return null
}
