import i18next from 'i18next'

export const BUTTONS_NAMESPACE = `buttons`

i18next.addResourceBundle(`ru`, BUTTONS_NAMESPACE, {
  share: `Поделиться`,
  clear: `Очистить`,
  download: `Скачать`,
})

i18next.addResourceBundle(`en`, BUTTONS_NAMESPACE, {
  share: `Share`,
  clear: `Clear`,
  download: `Download`,
})

i18next.addResourceBundle(`tr`, BUTTONS_NAMESPACE, {
  share: `Paylaşmak`,
  clear: `Temizlemek`,
  download: `İndirmek`,
})
