import { Dispatch, SetStateAction, useCallback } from 'react'
import { Application, DisplayObject, ICanvas } from 'pixi.js'
import { Button, IconButton } from '@chakra-ui/react'
import {
  FileDownloadOutlined,
  IosShareOutlined,
  ShareOutlined,
  CloseOutlined,
} from '@mui/icons-material'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { useBackgroundRemoval } from 'features/background-removal/lib'
import { isIOS, useIsMobile } from 'shared/lib'

import { BUTTONS_NAMESPACE } from './buttons.i18n'

import styles from './buttons.module.scss'

interface HeaderProps {
  setImageSource: Dispatch<SetStateAction<File | undefined>>
  pixiApp: Application<ICanvas> | undefined
  segmentation: ReturnType<typeof useBackgroundRemoval>['segmentation']
  className?: string
}

const getFileName = () => `blurred-image-${Date.now()}.png`

export const Buttons = ({
  setImageSource,
  pixiApp,
  segmentation,
  className,
}: HeaderProps) => {
  const handleClear = useCallback(() => {
    setImageSource(undefined)
  }, [setImageSource])

  const handleDownload = useCallback(async () => {
    if (!pixiApp) {
      return
    }

    const imageDataURL = await pixiApp.renderer.extract.base64(
      pixiApp.stage as DisplayObject,
    )
    const link = document.createElement(`a`)
    link.setAttribute(`href`, imageDataURL)
    link.setAttribute('download', getFileName())
    link.style.display = `none`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }, [pixiApp])

  const handleShare = async () => {
    if (!pixiApp) {
      return
    }

    const blob: Blob | null = await new Promise(
      (resolve) =>
        pixiApp.renderer.extract
          .canvas(pixiApp.stage as DisplayObject)
          .toBlob?.(resolve),
    )

    if (!blob) {
      return
    }

    const file = new File([blob], getFileName(), { type: blob.type })

    return navigator.share({
      files: [file],
    })
  }

  const { isMobile } = useIsMobile()
  const { t } = useTranslation(BUTTONS_NAMESPACE)

  return (
    <div className={classNames(styles.wrapper, className)}>
      {isMobile ? (
        <IconButton
          bg={'var(--primary-color)'}
          color={'white !important'}
          aria-label=''
          isDisabled={!segmentation}
          variant='solid'
          className={segmentation ? styles.icon : undefined}
          icon={<FileDownloadOutlined />}
          onClick={handleDownload}
        />
      ) : (
        <Button
          bg={'var(--primary-color)'}
          color={'white !important'}
          aria-label=''
          isDisabled={!segmentation}
          variant='solid'
          className={segmentation ? styles.icon : undefined}
          leftIcon={<FileDownloadOutlined />}
          onClick={handleDownload}
        >
          {t(`download`)}
        </Button>
      )}
      {isMobile ? (
        <IconButton
          aria-label=''
          isDisabled={!segmentation}
          variant='outline'
          className={segmentation ? styles.icon : undefined}
          icon={<CloseOutlined />}
          onClick={handleClear}
        />
      ) : (
        <Button
          aria-label=''
          isDisabled={!segmentation}
          variant='outline'
          className={segmentation ? styles.icon : undefined}
          leftIcon={<CloseOutlined />}
          onClick={handleClear}
        >
          {t(`clear`)}
        </Button>
      )}
      {!!navigator.canShare &&
        (isMobile ? (
          <IconButton
            aria-label=''
            isDisabled={!segmentation}
            variant='outline'
            className={segmentation ? styles.icon : undefined}
            icon={
              isIOS() ? (
                <IosShareOutlined className={styles.shareIcon} />
              ) : (
                <ShareOutlined className={styles.shareIcon} />
              )
            }
            onClick={handleShare}
          />
        ) : (
          <Button
            aria-label=''
            isDisabled={!segmentation}
            variant='outline'
            className={segmentation ? styles.icon : undefined}
            leftIcon={
              isIOS() ? (
                <IosShareOutlined className={styles.shareIcon} />
              ) : (
                <ShareOutlined className={styles.shareIcon} />
              )
            }
            onClick={handleShare}
          >
            {t(`share`)}
          </Button>
        ))}
    </div>
  )
}
