import { useState, useEffect } from 'react'

const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches)

  useEffect(() => {
    const media = window.matchMedia(query)
    const listener = () => {
      setMatches(media.matches)
    }
    media.addEventListener(`change`, listener)
    return () => media.removeEventListener(`change`, listener)
  }, [query])

  return { matches }
}

export const useIsMobile = () => {
  const { matches } = useMediaQuery(`(min-width: 768px)`)

  return { isMobile: !matches }
}
