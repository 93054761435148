import { useColorMode } from '@chakra-ui/react'
import { useEffect } from 'react'

const media = window.matchMedia(`(prefers-color-scheme: dark)`)

export const ColorMode = () => {
  const { setColorMode } = useColorMode()

  useEffect(() => {
    const handleMediaChange = (event: MediaQueryListEvent) => {
      setColorMode(event.matches ? `dark` : `light`)
    }

    setColorMode(media.matches ? `dark` : `light`)

    media.addEventListener(`change`, handleMediaChange)
    return () => {
      media.removeEventListener(`change`, handleMediaChange)
    }
  }, [setColorMode])

  return null
}
