import './app.scss'
import './i18n'

import React from 'react'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'

import { Editor } from 'pages/editor/ui'

import { YandexGamesSdk } from './yandex-games-sdk'
import { theme } from 'app/theme'
import { ColorMode } from 'app/color-mode'
import { YandexAdv } from 'app/yandex-adv'

const media = window.matchMedia(`(prefers-color-scheme: dark)`)

export const App = () => {
  return (
    <>
      <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={media.matches ? `dark` : `light`} />
        <ColorMode />
        <Editor />
        <YandexGamesSdk />
        <YandexAdv />
      </ChakraProvider>
    </>
  )
}
